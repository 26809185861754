import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useForm } from "react-hook-form";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Link } from "./link";

const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

const BoxNewsletter = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
  } = useForm();

  const [error, setError] = useState(false);

  const onSubmit = async (data) => {
    if (!executeRecaptcha) {
      console.warn("Execute recaptcha not yet available");
      return;
    }
    const token = await executeRecaptcha("newsletterForm");
    console.log(data);
    setError(false);
    try {
      const response = await fetch("/api/newsletter", {
        method: "POST",
        body: JSON.stringify({
          email: data.email,
          captcha: token,
        }),
      });
      if (!response.ok) {
        throw new Error(`Error calling /api/newsletter: ${response.status} ${response.statusText}`);
      }
      const result = await response.json();
      console.log("Newsletter result: ", result);
    } catch (err) {
      setError(true);
      console.error("Error newsletter: ", err);
    }
  };

  const { newsletter } = useStaticQuery(graphql`
    {
      newsletter: file(relativePath: { eq: "bg-newsletter.png" }) {
        childImageSharp {
          gatsbyImageData(width: 1920, layout: CONSTRAINED)
        }
      }
    }
  `);
  return (
    <>
      <section id="newsletter" className="section">
        <div className="box-newsletter">
          <GatsbyImage
            image={getImage(newsletter)}
            alt="img background help"
            objectPosition="center center"
            className="box-newsletter__bg-img"
          />
          <div className="container-md">
            <div className="row">
              <div className="col-12">
                <div className="box-newsletter__title text-uppercase">
                  Iscriviti alla nostra Newsletter
                </div>
                <div className="box-newsletter__text text-uppercase">
                  Tutte le informazioni e gli aggiornamenti sull'evento.
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                {isSubmitSuccessful ? (
                  <div className="box-newsletter__text text-uppercase text-center">
                    Grazie per esserti iscritto.
                  </div>
                ) : (
                  <form onSubmit={handleSubmit(onSubmit)} className="form pb-0">
                    <div className="d-lg-flex">
                      <div>
                        <div className="form__group mt-3">
                          <div className="form__wrap-input-text">
                            <input
                              type="text"
                              className="form__input-text"
                              name="email"
                              id="email"
                              placeholder=" "
                              {...register("email", {
                                required: {
                                  value: true,
                                  message: "Specificare un indirizzo email.",
                                },
                                pattern: {
                                  value: EMAIL_REGEX,
                                  message: "Specificare un indirizzo email valido.",
                                },
                              })}
                            />
                            <label className="form__label" htmlFor="email">
                              Inserisci la tua email
                            </label>
                          </div>
                          {errors.email ? (
                            <span className="d-block form__error">{errors.email.message}</span>
                          ) : null}
                          {error && !errors.email ? (
                            <span className="d-block form__error">
                              Ops, si è verificato un errore. Riprova.
                            </span>
                          ) : null}
                        </div>
                        <div className="">
                          <label className="checkbox">
                            <div>
                              Confermo di aver preso visione dell'
                              <Link to="/privacy-policy/">
                                informativa sul trattamento dei dati
                              </Link>
                            </div>
                            <input
                              type="checkbox"
                              name="privacy"
                              id="privacy"
                              className="checkbox__input"
                              {...register("privacy", {
                                required: {
                                  value: true,
                                  message: "Acconsentire al trattamento dei dati.",
                                },
                              })}
                            />
                            <span className="checkbox__mark"></span>
                          </label>
                          {errors.privacy ? (
                            <span className="d-block form__error">{errors.privacy.message}</span>
                          ) : null}
                        </div>
                      </div>
                      <div>
                        <button type="submit" className="btn-link btn-link--small">
                          <span className="btn-link__circle"></span>
                          <span className="btn-link__text bold">Iscriviti</span>
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BoxNewsletter;
