import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { Link } from "./link";

const BoxSponsor = () => {
  const { liferay, intranet8020, huware } = useStaticQuery(graphql`
    {
      liferay: file(relativePath: { eq: "partners/logo-liferay.png" }) {
        childImageSharp {
          gatsbyImageData(height: 60, placeholder: BLURRED)
        }
      }
      intranet8020: file(relativePath: { eq: "partners/logo-intranet-8020.png" }) {
        childImageSharp {
          gatsbyImageData(height: 60, placeholder: BLURRED)
        }
      }
      huware: file(relativePath: { eq: "partners/huware.png" }) {
        childImageSharp {
          gatsbyImageData(height: 55, placeholder: BLURRED)
        }
      }
      kaltura: file(relativePath: { eq: "partners/logo-kaltura-2022.png" }) {
        childImageSharp {
          gatsbyImageData(height: 60, placeholder: BLURRED)
        }
      }
      reply: file(relativePath: { eq: "partners/logo-reply.png" }) {
        childImageSharp {
          gatsbyImageData(height: 60, placeholder: BLURRED)
        }
      }
      utlab: file(relativePath: { eq: "partners/logo-utlab.png" }) {
        childImageSharp {
          gatsbyImageData(height: 60, placeholder: BLURRED)
        }
      }
    }
  `);
  return (
    <>
      <section className="section section--grey-dark">
        <div className="box-info">
          <div className="container-md">
            <div className="row">
              <div className="col-12 col-lg-4 order-2 order-lg-1 text-center text-lg-left">
                <Link to="/diventa-sponsor/" className="btn-link mt-5 mt-lg-0">
                  <span className="btn-link__text">
                    Diventa{" "}
                    <span className="d-block">
                      <strong>sponsor</strong>
                    </span>
                  </span>
                  <span className="btn-link__circle"></span>
                </Link>
              </div>
              <div className="col-12 col-lg-8 order-1 order-lg-2">
                <div className="box-info__text">
                  <h2 className="box-info__title">Grazie ai nostri sponsor</h2>
                  <p>
                    Gli sponsor di IID sono scelti sulla base della reale focalizzazione sul tema
                    delle intranet e della comunicazione interna. Sono realtà del settore che
                    possono davvero essere molto utili alle aziende e che portano all'iniziativa
                    contenuti di valore per la community.
                  </p>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-6 d-flex flex-column justify-content-end align-items-start">
                    <GatsbyImage
                      image={getImage(huware)}
                      alt="Huware"
                      objectPosition="center center"
                      className="box-info__img"
                    />
                    <div className="box-info__label w-100">Platinum</div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <GatsbyImage
                      image={getImage(intranet8020)}
                      alt="Intranet 80.20"
                      objectPosition="center center"
                      className="box-info__img"
                    />
                    <div className="box-info__label">Platinum</div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <GatsbyImage
                      image={getImage(liferay)}
                      alt="Liferay"
                      objectPosition="center center"
                      className="box-info__img"
                    />
                    <div className="box-info__label">Main sponsor</div>
                  </div>
                  {/* <div className="col-12 col-sm-6">
                    <GatsbyImage
                      image={getImage(hrccom)}
                      alt="HRC Community"
                      objectPosition="center center"
                      className="box-info__img"
                    />
                    <div className="box-info__label">Community Partner</div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <GatsbyImage
                      image={getImage(utlab)}
                      alt="User Test Lab"
                      objectPosition="center center"
                      className="box-info__img"
                    />
                    <div className="box-info__label">Partner</div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <GatsbyImage
                      image={getImage(kaltura)}
                      alt="Kaltura"
                      objectPosition="center center"
                      className="box-info__img"
                    />
                    <div className="box-info__label">Partner</div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <GatsbyImage
                      image={getImage(reply)}
                      alt="Replay"
                      objectPosition="center center"
                      className="box-info__img"
                    />
                    <div className="box-info__label">Partner</div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BoxSponsor;
